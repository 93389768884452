/* @tailwind base;
@tailwind components;
@tailwind utilities; */

@layer base {
  :root {
    --color-primary: #103365; /* blue 900 */
    --color-secondary: #4e5058; /* gray 600 */
    --color-secondary: #aeb9c7; /* blue 100 */
  }
}

@layer tailwind-base, primereact, tailwind-utilities;

/* IMPORTANT: In "styled" mode you must add the PrimeReact Theme here. Do NOT include in "unstyled" mode */
/* prettier-ignore */
/* @import "primereact/resources/themes/lara-light-blue/theme.css" layer(primereact); */

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

/* HEX: #3B5998

HEX: #8B9DC3

HEX: #DFE3EE

HEX: #F7F7F7 */

/* 
https://color.adobe.com/create/color-contrast-analyzer
Text color: #030815 
background: #FFFFFF
*/

/* Heading Menu background with white text : #A9CEF4
Lighter #7EA0B7
Dark #597081
Darker #36494E
Darkest #000000 */

/* Heading - #1E2019
Lightest - #C4CAD0
#A4969B
Darker - #394032 */

/* Heading menu - #AEB9C7 , black font
Intermediate grey - #4E5058 - secondary button
Dark highlight - #103365, primary button */
